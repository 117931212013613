import { usersLogOut } from '@apis/api/auth';
import router from 'next/router';
import { getLoginPageUrlObject } from 'routes/account';
import * as amplitude from '@amplitude/analytics-browser';
import cookieCutter from 'cookie-cutter';
import SendbirdChat from '@sendbird/chat';
import { getSendbirdUserInfo } from '@apis/api/chat';
import _ from 'lodash';
import { unregisterPushToken } from '@apis/api/user';
import { UserType } from '@asset/enum/UserEnum';
import { Settings } from 'luxon';

export const logoutAndResetUserInfo = async (userId: number) => {
  try {
    const sendbirdUserInfo = await getSendbirdUserInfo(userId);
    const sb = SendbirdChat.init({
      appId: process.env.NEXT_PUBLIC_SEND_BIRD_APP_ID,
    });
    const sendbirdUserId = sendbirdUserInfo?.userId;
    const sendbirdAccessToken = sendbirdUserInfo?.accessToken;
    if (!_.isNil(sendbirdUserId) && !_.isNil(sendbirdAccessToken)) {
      await sb.connect(sendbirdUserInfo?.userId, sendbirdUserInfo?.accessToken);
      await sb.unregisterFCMPushTokenAllForCurrentUser();
      await sb.disconnect();
    }
  } catch {
    console.error('Failed to unregister FCM token to sendbird.');
  }
  try {
    await unregisterPushToken(userId);
  } catch {
    console.error('Failed to unregister FCM token to almighty API Server.');
  }
  await usersLogOut();
  await router.replace(getLoginPageUrlObject({}));
  amplitude.reset();
  cookieCutter.set('NEXT_LOCALE', '', {
    path: '/',
  });
};

export const initTimeZoneAndLanguage = (userInfo) => {
  if (userInfo.type === UserType.STUDENT)
    Settings.defaultZone = userInfo.timezone;
  cookieCutter.set('NEXT_LOCALE', userInfo?.language ?? null, {
    path: '/',
  });
};
