import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import CustomScheduledMessageSection from '../CustomScheduledMessageSection';
import CustomChannelHeader from '../CustomChannelHeader';
import * as S from '@styles/chat.style';
import { useEffect, useRef, useState } from 'react';
import { GroupChannelHeaderProps } from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { DateTime } from 'luxon';
import {
  RenderCustomSeparatorProps,
  RenderMessageParamsType,
} from '@sendbird/uikit-react/types/types';
import CustomMessage from '../CustomMessage';
import GroupChannelFooter from './GroupChannelFooter';
import {
  GroupChannelProvider,
  useGroupChannelContext,
} from '@sendbird/uikit-react/GroupChannel/context';
import { useUserInfoQuery } from '@apis/query/user';
import { useQuery } from 'react-query';
import { getStudentInfo } from '@apis/api/dashboard';
import _ from 'lodash';
import { ChannelMetaData } from '@asset/type/chat';
import { addTypeChannelMetaData } from '@asset/function/chat';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useChatStore } from '@store/chat';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { UserEventHandler } from '@sendbird/chat';
import { UnreadMessageCount } from '@sendbird/chat/groupChannel';

const CustomGroupChannel = ({
  onCloseGroupChannel,
}: {
  onCloseGroupChannel: () => void;
}) => {
  const { data: user } = useUserInfoQuery();
  const { selectedChannelUrl, setUnreadCount } = useChatStore();
  const { stores } = useSendbirdStateContext();
  const { currentChannel } = useGroupChannelContext();
  const [isOpenScheduledMessageSection, setIsOpenScheduledMessageSection] =
    useState(false);
  const channelInputRef = useRef<HTMLTextAreaElement | null>(null);

  const { data: selectedChannelMetaData } = useQuery(
    ['GET_CHANNEL_META_DATA', currentChannel?.url],
    async (): Promise<ChannelMetaData> => {
      return await currentChannel
        .getAllMetaData()
        .then((response) => {
          return addTypeChannelMetaData(response);
        })
        .catch(() => null);
    },
    { enabled: !!currentChannel },
  );

  const { data: studentInfo } = useQuery(
    ['GET_STUDENT_INFO', selectedChannelMetaData],
    async () => await getStudentInfo(Number(selectedChannelMetaData.studentId)),
    { enabled: !_.isNil(selectedChannelMetaData?.studentId) },
  );

  useEffect(() => {
    const userEventHandler: UserEventHandler = new UserEventHandler({
      onTotalUnreadMessageCountChanged: (
        unreadMessageCount: UnreadMessageCount,
      ) => {
        setUnreadCount(unreadMessageCount.groupChannelCount);
      },
    });

    if (stores?.sdkStore?.sdk?.addUserEventHandler) {
      stores?.sdkStore?.sdk?.addUserEventHandler(
        'onTotalUnreadMessageCountUpdated',
        userEventHandler,
      );
    }
    return () => {
      if (stores?.sdkStore?.sdk?.removeUserEventHandler) {
        stores?.sdkStore?.sdk?.removeUserEventHandler(
          'onTotalUnreadMessageCountUpdated',
        );
      }
    };
  }, [stores?.sdkStore?.sdk?.addUserEventHandler]);

  if (!_.isNil(studentInfo)) {
    return (
      <div className="w-full h-full">
        {isOpenScheduledMessageSection ? (
          <CustomScheduledMessageSection
            toggleScheduledMessageSectionVisible={(isOpen) =>
              setIsOpenScheduledMessageSection(isOpen)
            }
          />
        ) : (
          <GroupChannel
            channelUrl={selectedChannelUrl}
            renderChannelHeader={(props: GroupChannelHeaderProps) => (
              <CustomChannelHeader
                GroupChannelHeaderProps={props}
                studentInfo={studentInfo}
                onCloseGroupChannel={onCloseGroupChannel}
              />
            )}
            renderCustomSeparator={(props: RenderCustomSeparatorProps) => (
              <S.ChatSeparator>
                {DateTime.fromMillis(props.message.createdAt).toFormat(
                  'yyyy년 MM월 dd일 cccc',
                  {
                    locale: user.language ?? 'ko',
                  },
                )}
              </S.ChatSeparator>
            )}
            renderMessage={(props: RenderMessageParamsType) => (
              <CustomMessage
                messageProps={props}
                studentInfo={studentInfo}
                channelInputRef={channelInputRef}
              />
            )}
            renderMessageInput={() => (
              <GoogleOAuthProvider
                clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
              >
                <GroupChannelFooter
                  channelInputRef={channelInputRef}
                  toggleScheduledMessageSectionVisible={(isOpen) =>
                    setIsOpenScheduledMessageSection(isOpen)
                  }
                />
              </GoogleOAuthProvider>
            )}
          />
        )}
      </div>
    );
  }
};

const CustomGroupChatRoom = ({
  onCloseGroupChannel,
}: {
  onCloseGroupChannel: () => void;
}) => {
  const { selectedChannelUrl } = useChatStore();
  return (
    <section className="flex min-h-[1px] flex-grow w-full">
      <GroupChannelProvider channelUrl={selectedChannelUrl}>
        <CustomGroupChannel onCloseGroupChannel={onCloseGroupChannel} />
      </GroupChannelProvider>
    </section>
  );
};

export default CustomGroupChatRoom;
