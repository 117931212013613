export enum ChatMessageType {
  GOOGLE_MEET = 'google-meet',
  FILE = 'file',
  SCHEDULED = 'scheduled',
  QUESTION = 'question',
}

export enum ChatMessageMenu {
  REPLY = 'reply',
  ANNOUNCE = 'announce',
  EDIT = 'edit',
  DELETE = 'delete',
}
