import { useEffect, useRef, useState } from 'react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';

import { useChatStore } from '@store/chat';
import IconContainer from '@components/common/IconContainer';

import * as S from '@styles/chat.style';
import SendIcon from '@asset/svg/SendIcon';

const TYPING_TIMEOUT = 3000;
const LINE_HEIGHT = 20;
const MIN_LINES = 1;
const MAX_LINES = 8;

const GroupChannelFooterInput = ({
  channelInputRef,
}: {
  channelInputRef: React.RefObject<HTMLTextAreaElement>;
}) => {
  const { currentChannel, sendUserMessage, scrollToBottom } =
    useGroupChannelContext();
  const { clickedMessageToReply, setClickedMessageToReply } = useChatStore();

  const typingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isComposingRef = useRef<boolean>(false);

  const [isFocused, setIsFocused] = useState(false);
  const [hasText, setHasText] = useState(false);

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  const adjustTextareaHeight = () => {
    if (!channelInputRef.current) return;
    const textarea = channelInputRef.current;

    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    const textHeight = textarea.scrollHeight;
    const newRows = Math.min(
      Math.max(Math.ceil(textHeight / LINE_HEIGHT), MIN_LINES),
      MAX_LINES,
    );
    textarea.style.overflow = 'auto';
    textarea.style.height = `${newRows * LINE_HEIGHT}px`;
  };

  const handleSendMessage = () => {
    const message = channelInputRef.current?.value;
    if (!message.trim()) return;

    const params = clickedMessageToReply
      ? {
          message,
          parentMessageId: clickedMessageToReply.messageId,
          isReplyToChannel: true,
        }
      : { message, parentMessageId: null };
    sendUserMessage(params);

    if (channelInputRef.current) {
      channelInputRef.current.value = '';
    }
    currentChannel?.endTyping();
    setClickedMessageToReply(null);
    setHasText(false);
    adjustTextareaHeight();
    setTimeout(() => {
      scrollToBottom(true);
    }, 1000);
  };

  const handleKeyDown = (e) => {
    if (isComposingRef.current) return;
    channelInputRef.current.value = (e.target as HTMLTextAreaElement).value;
    const message = channelInputRef.current?.value;
    if (e.key === 'Enter' && !e.shiftKey && message?.trim()) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleTyping = () => {
    if (!currentChannel) return;
    currentChannel.startTyping();

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      currentChannel.endTyping();
    }, TYPING_TIMEOUT);
    setHasText(!!channelInputRef.current?.value.trim());
    adjustTextareaHeight();
  };

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      setClickedMessageToReply(null);
    };
  }, []);

  return (
    <S.MessageInput
      isFocused={isFocused}
      minHeight={`${LINE_HEIGHT}px`}
      maxHeight={`${LINE_HEIGHT * MAX_LINES}px`}
    >
      <div>
        <textarea
          rows={1}
          ref={channelInputRef}
          placeholder="메시지를 입력하세요."
          onKeyDown={handleKeyDown}
          onChange={handleTyping}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        ></textarea>
        {/* 이 후 추가 예정 */}
        {/* <div>photo zone</div> */}
      </div>
      <IconContainer
        icon={
          <SendIcon
            width="1.5rem"
            height="1.5rem"
            color={hasText ? 'var(--blue-50)' : 'var(--gray-60)'}
          />
        }
        size={'M'}
        onClick={handleSendMessage}
        className="send-button"
      ></IconContainer>
    </S.MessageInput>
  );
};

export default GroupChannelFooterInput;
