import { useState } from 'react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import GroupChannelHeader, {
  GroupChannelHeaderProps as GroupChannelHeaderPropsType,
} from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useUserInfoQuery } from '@apis/query/user';
import { UserType } from '@asset/enum/UserEnum';
import { StudentInformation } from '@apis/api/dashboard';
import { useChatStore } from '@store/chat';
import UserAvatarProfile from '@components/common/UserAvatarProfile';
import {
  getStudentStudyStatus,
  translateStudyStatusStepEngToKor,
} from '@asset/function/commonFunctions';
import IconContainer from '@components/common/IconContainer';
import * as S from '@styles/chat.style';
import CloseIcon from '@asset/svg/CloseIcon';
import AnnouncementIcon from '@asset/svg/AnnouncementIcon';
import BackIcon from '@asset/svg/BackIcon';
import { useQuery } from 'react-query';
import { getStudentsExtrasApi } from '@apis/api/student/common';
import { MetaData } from '@sendbird/chat';
import BasicArrowTooltip from '@components/common/tool-tip/BasicArrowTooltip';
import { useRouter } from 'next/router';
import { getManageStudentStudyContentUrlObject } from 'routes/studyContent';
import { StudyContentType } from '@asset/enum/materialEnum';
import { ChannelMetaData, GroupChannelCustomType } from '@asset/type/chat';
import { addTypeChannelMetaData } from '@asset/function/chat';
import MoreIcon from '@asset/svg/MoreIcon';
import BasicMenu, {
  BasicMenuItemContent,
} from '@components/common/list/BasicMenu';

const CustomChannelHeader = ({
  GroupChannelHeaderProps,
  studentInfo,
  onCloseGroupChannel,
}: {
  GroupChannelHeaderProps: GroupChannelHeaderPropsType;
  studentInfo: StudentInformation;
  onCloseGroupChannel: () => void;
}) => {
  const router = useRouter();
  const { currentChannel } = useGroupChannelContext();
  const { data: user } = useUserInfoQuery();
  const { setIsOpen } = useChatStore();

  const [channelMetaData, setChannelMetaData] = useState<ChannelMetaData>(
    currentChannel
      ? addTypeChannelMetaData(currentChannel.cachedMetaData as MetaData)
      : null,
  );

  const getChannelMetaData = async () => {
    setChannelMetaData(
      await currentChannel
        .getAllMetaData()
        .then((response) => addTypeChannelMetaData(response)),
    );
  };
  if (_.isNil(channelMetaData) && !_.isNil(currentChannel)) {
    getChannelMetaData();
  }

  const { data: studentExtras } = useQuery(
    ['GET_STUDENTS_EXTRAS', channelMetaData],
    async () =>
      (
        await getStudentsExtrasApi({
          studentIds: [channelMetaData.studentId],
        })
      ).data[0],
    { enabled: !_.isNil(channelMetaData) },
  );
  const {
    color: statusColor,
    timeText,
    statusText,
  } = getStudentStudyStatus(
    studentExtras?.recentStudyStatusInfo?.studentCompletedAt
      ? DateTime.fromISO(
          studentExtras?.recentStudyStatusInfo?.studentCompletedAt,
        )
      : null,
    currentChannel?.customType === GroupChannelCustomType.IN_CLASS,
    studentExtras?.recentStudyStatusInfo?.elapsedTime,
    studentExtras?.recentStudyStatusInfo?.studyItemType,
  );
  const studyStatusData = studentExtras?.recentStudyStatusInfo;

  return (
    <S.GroupChannelHeader>
      <GroupChannelHeader
        {...GroupChannelHeaderProps}
        className="drag-handle"
        renderLeft={() =>
          user?.type === UserType.TEACHER ? (
            <IconContainer
              icon={<BackIcon width="20" height="20" />}
              size={'M'}
              onClick={() => onCloseGroupChannel()}
              className="chat-header-button"
            ></IconContainer>
          ) : (
            <></>
          )
        }
        renderMiddle={() => (
          <div className="flex items-center gap-2 cursor-default">
            {user && user.type === UserType.TEACHER && (
              <div className="relative flex w-8 h-8 rounded-[50%]">
                {studentExtras && (
                  <UserAvatarProfile
                    name={studentExtras.avatarName}
                    variant="beam"
                  />
                )}
                <div className="absolute bottom-[-2px] right-[-2px]">
                  <div
                    className={`w-2 h-2 border-[2px] border-solid rounded-full border-my-gray-100 box-content`}
                    style={{ background: statusColor }}
                  ></div>
                </div>
              </div>
            )}

            <div>
              <div className="text-body-lg-700 text-my-gray-10">
                {studentInfo &&
                  user &&
                  (user.type === UserType.TEACHER
                    ? studentInfo.name
                    : studentInfo.teacherName + ' 선생님')}
                {user && user.type === UserType.TEACHER ? (
                  statusText ? (
                    <BasicArrowTooltip
                      arrow
                      placement="top"
                      title={
                        studyStatusData
                          ? `${studyStatusData.course} / ${
                              studyStatusData.materialType
                            } / ${studyStatusData.materialName} | ${
                              studyStatusData.indexNameType
                            } ${studyStatusData.indexNumber} / ${
                              studyStatusData.division
                            } ${
                              studyStatusData.subIndexNumber
                            } | ${translateStudyStatusStepEngToKor(
                              studyStatusData.studyStatusType,
                            )}`
                          : ''
                      }
                      disableHoverListener={studyStatusData === null}
                    >
                      <div
                        className={`relative underline cursor-pointer text-label-sm-500 text-my-blue-50 break-keep`}
                        onClick={(e) => {
                          router.push(
                            getManageStudentStudyContentUrlObject(
                              studyStatusData.assignmentId
                                ? StudyContentType.MY_ASSIGNMENT
                                : studyStatusData.myStudyContentType ===
                                    StudyContentType.MY_WRONG_TEST ||
                                  studyStatusData.myStudyContentType ===
                                    StudyContentType.MY_LEVEL_TEST
                                ? StudyContentType.MY_TEST
                                : studyStatusData.myStudyContentType,
                              studyStatusData.assignmentId
                                ? studyStatusData.assignmentId
                                : studyStatusData.myStudyContentId,
                              {
                                studentId: channelMetaData.studentId,
                                modalType: 'studentAnswer',
                                pageId: studyStatusData.pageId,
                                studyItemType: studyStatusData.studyItemType,
                                problemStatusId:
                                  studyStatusData.problemStatusId,
                                problemStatusDetailId:
                                  studyStatusData.problemStatusDetailId,
                              },
                            ),
                          );
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        {statusText}: {timeText}
                      </div>
                    </BasicArrowTooltip>
                  ) : (
                    <div className={`text-label-sm-500 text-my-gray-60`}>
                      {timeText}
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
        renderRight={() => (
          <IconContainer
            icon={<CloseIcon width="20" height="20" color="var(--gray-10)" />}
            size={'M'}
            className="chat-header-button"
            onClick={() => {
              onCloseGroupChannel();
              setIsOpen(false);
            }}
          ></IconContainer>
        )}
      ></GroupChannelHeader>
      <PinnedMessageSection />
    </S.GroupChannelHeader>
  );
};

export default CustomChannelHeader;

const PinnedMessageSection = () => {
  const { data: user } = useUserInfoQuery();
  const { scrollToMessage, currentChannel } = useGroupChannelContext();
  const [isOpenPinnedMessageMenu, setIsOpenPinnedMessageMenu] = useState(false);

  if (!_.isNil(currentChannel?.lastPinnedMessage)) {
    return (
      <S.PinnedMessageSection
        className="chat-announcement"
        onClick={() =>
          scrollToMessage(
            currentChannel.lastPinnedMessage.createdAt,
            currentChannel.lastPinnedMessage.messageId,
          )
        }
      >
        <div className="icon">
          <AnnouncementIcon color="var(--gray-30)" />
        </div>
        <div className="content">
          {currentChannel.lastPinnedMessage.message}
        </div>
        {user && user.type === UserType.TEACHER && (
          <div className="more-button">
            <IconContainer
              icon={<MoreIcon width="24" height="24" color="var(--gray-10)" />}
              size={'M'}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenPinnedMessageMenu(!isOpenPinnedMessageMenu);
              }}
              onTouchEnd={(e) => e.stopPropagation()}
            />
            {isOpenPinnedMessageMenu && (
              <BasicMenu
                items={[
                  {
                    id: 'unpin',
                    content: (
                      <BasicMenuItemContent
                        text={'공지 해제'}
                        customStyles={{ width: '7rem' }}
                      />
                    ),
                  },
                ]}
                onClickAway={() => setIsOpenPinnedMessageMenu(false)}
                onClickItem={async (menu) => {
                  if (menu.id === 'unpin') {
                    await currentChannel.unpinMessage(
                      currentChannel.lastPinnedMessage.messageId,
                    );
                    setIsOpenPinnedMessageMenu(!isOpenPinnedMessageMenu);
                  }
                }}
                customStyles={{
                  top: '110%',
                }}
              />
            )}
          </div>
        )}
      </S.PinnedMessageSection>
    );
  }
};
