import styled, { createGlobalStyle, css } from 'styled-components';
import tw from 'twin.macro';
import { BasicMenuCss } from './styles';
import { UserType } from '@asset/enum/UserEnum';
import {
  getActiveElementBackground,
  getHoveredElementBackground,
} from './globalsStyles';
import { CHAT_ROOM_Z_INDEX } from 'constants/chat';

export const chatRoomRemWidth = 23;
const ChatRoomHeaderRemWidth = 4;
const messageBlueColor = 'var(--blue-95)';
const messageGrayColor = 'var(--gray-90)';

export const ChatGlobalStyle = createGlobalStyle`
  [class*=sendbird-label] {
    font-family: Pretendard, sans-serif, 'Apple SD Gothic Neo', 'Malgun Gothic',
    '맑은 고딕', '돋움', Dotum, '돋움체' !important;
  }
  
  .drag-handle {
    ${tw`cursor-pointer`}
  }
  
  div.sendbird-channel-list {
    ${tw`w-full`}
    div.sendbird-channel-list__header {
      ${tw`h-fit`}
    }
  }

  .sendbird-notification {
    ${tw`hidden`}
  }
  
  div.sendbird-conversation {
    ${tw`!border-none`}

    .sendbird-conversation__scroll-bottom-button {
      ${tw`border border-solid rounded-[62.4375rem] border-my-gray-10 py-2 px-3 w-fit z-1`}
      left: 50%;
      bottom: 1rem;
      transform: translateX(-50%);
      
      &::before{
        content: ' ';
        ${tw`w-5 h-5 mt-1`}
        background-image: url('/common/icon/ArrowDownwardAlt.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      &::after{
        content: '맨 아래로 이동';
        ${tw`ml-1 text-label-lg-600 text-my-gray-10`}
      }
      > div{
        ${tw`hidden`}
      }
    }

    .sendbird-conversation__messages-padding {
      overscroll-behavior: contain;
      padding: 0 0.75rem 0 1rem;
    }
  }
  div#sendbird-emoji-list-portal{
    ul.sendbird-dropdown__reaction-bar {
      ${tw`flex gap-1 p-1 border border-solid rounded-lg border-my-gray-80`}

      .sendbird-reaction-button:hover, .sendbird-reaction-button--selected:hover {
        ${tw`bg-Hover`}
      }

      .sendbird-reaction-button--selected {
        ${tw`border-none bg-Hover`}
      }
    }

  }
  ul.sendbird-dropdown__menu{
    ${BasicMenuCss}
    li {
      > span{
        ${tw`flex items-center justify-start gap-2 text-body-md-500 text-my-gray-10`}
      } 
      &:hover, &:active {
        background:${getHoveredElementBackground(
          'var(--gray-100)',
        )} !important ;
      }
    }
  } 
  div.sendbird-conversation__footer{
    ${tw`flex flex-col justify-end w-full pb-2`}
    
    div.sendbird-conversation__footer__typing-indicator{
      ${tw`hidden`}
    }
  }
  #sendbird-modal-root{
    div.sendbird-modal__content{
      ${tw`rounded-xl w-[25rem]`}
      div.sendbird-modal__header{
        ${tw`flex items-center justify-center`}
        > span.sendbird-label{
          ${tw`text-title-lg-700`}
        }
        >div.sendbird-modal__close {
          ${tw`hidden`}
        }
      }
      div.sendbird-modal__footer{
        ${tw`flex items-center justify-center`}
        > button{
          ${tw`flex-grow px-6 py-4 rounded-lg h-fit`}
          > span.sendbird-button__text{
            ${tw`m-0`}
            span.sendbird-label{
              ${tw`text-label-lg-700 text-my-gray-10`}
            }
          }
        }
      }
    }
  } 

  .sendbird-multiple-files-image-renderer__thumbnail__placeholder, .sendbird-multiple-files-image-renderer__thumbnail__placeholder__icon {
    ${tw`!flex !items-center !justify-center !w-full !h-full !min-w-full`}
  }
`;

export const ChatRoom = styled.article`
  & {
    ${tw`fixed`}
    top: calc(var(--height-global-nav) + 1rem);
    left: ${`calc(100% - ${chatRoomRemWidth}rem - 1rem)`};
    z-index: ${CHAT_ROOM_Z_INDEX};
  }
`;

export const GroupChannelHeader = styled.div`
  & {
    .sendbird-chat-header {
      ${tw`py-3 pl-5`}
      height: ${ChatRoomHeaderRemWidth}rem !important;
      min-height: ${ChatRoomHeaderRemWidth}rem !important;
    }
  }
`;

export const ChatRoomHeaderLayout = styled.div`
  & {
    ${tw`box-border flex items-center justify-between w-full gap-2 py-4 pl-5 pr-4`}
    ${tw`border-b border-solid border-my-gray-80`}
    height: ${ChatRoomHeaderRemWidth}rem;

    .title {
      ${tw`text-body-lg-700 text-my-gray-10`}
    }
    .middle {
      ${tw`items-center flex-grow`}
    }
  }
`;

export const GroupChannelListFilteringSection = styled.div`
  & {
    ${tw`flex items-center justify-start gap-2 px-4 py-2`}

    button {
      ${tw`flex items-center justify-center border-solid rounded-lg`}
      ${tw`px-3 py-2 border text-label-md-600 text-my-gray-20 border-my-gray-70`}
    }
    button.selected {
      ${tw`text-label-md-700 text-my-blue-50 border-[2px] border-my-blue-60 py-[calc(0.5rem-1px)] px-[calc(0.75rem-1px)] bg-my-blue-95`}
    }
  }
`;

export const PinnedMessageSection = styled.div`
  & {
    ${tw`flex items-center h-16 gap-2 px-4 py-3 border-b border-solid cursor-pointer border-my-gray-80 `}
    box-shadow: 0px 2px 12px 0px color(display-p3 0.098 0.1255 0.1608 / 0.10);
    > .icon {
      ${tw`flex items-start h-full pt-1`}
    }
    > .content {
      ${tw`flex-grow h-full overflow-hidden text-body-md-500 text-my-gray-10`}
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
    }
    > .more-button {
      ${tw`relative`}
    }
  }
`;

export const ChatSeparator = styled.div`
  & {
    ${tw`flex justify-center w-full py-3 text-my-gray-50 text-label-sm-500`}
  }
`;

const MessageMenuButton = css`
  ${tw`relative rounded-lg`}
  width: 1.75rem;
  height: 1.75rem;
  .sendbird-iconbutton__inner {
    ${tw`flex items-center justify-center`}
    .sendbird-message-item-reaction-menu__trigger,
    .sendbird-iconbutton {
      ${tw`!p-0 !border-none !m-0`}
      width: 1.75rem !important;
      height: 1.75rem !important;

      .sendbird-message-item-reaction-menu__trigger__icon,
      .sendbird-icon-more {
        ${tw`flex items-center justify-center`}
      }

      button.sendbird-iconbutton {
        &:hover {
          background: ${getHoveredElementBackground('var(--gray-100)')};
        }
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
  button.sendbird-iconbutton--pressed {
    background: ${getActiveElementBackground('var(--gray-100)')};
  }
`;

export const ChatMessageMenuButton = styled.div`
  & {
    ${MessageMenuButton}
  }
`;

export const ChatMessageEmojiMenuButton = styled.div`
  & {
    ${MessageMenuButton}
  }
`;

export const ChatMessageEmojiReactionContainer = styled.div`
  .sendbird-emoji-reactions {
    ${tw`!border-none px-0 flex gap-1 relative z-1`}

    > .sendbird-emoji-reactions__reaction-badge {
      ${tw`m-0`}

      .sendbird-reaction-badge, .sendbird-reaction-badge--selected {
        ${tw`flex items-center h-6 px-2 py-1 border border-solid rounded-lg w-fit`}
        .sendbird-reaction-badge__inner {
          ${tw`flex items-center gap-1 p-0 h-fit`}
          > .sendbird-reaction-badge__inner__icon {
            ${tw`relative top-0 left-0`}
            > .sendbird-image-renderer {
              ${tw`!min-w-[1rem] !h-[1rem]`}
              > .sendbird-image-renderer__image {
                ${tw`!min-w-[1rem] !h-[1rem]`}
              }
            }
          }
          > .sendbird-reaction-badge__inner__count {
            ${tw`ml-0 text-label-sm-700 text-my-gray-30 w-fit`}
            display: ${(props) =>
              props.userType === UserType.STUDENT ? 'none' : 'flex'};
          }
        }
      }
      .sendbird-reaction-badge {
        ${tw`border-my-gray-70 bg-my-gray-95`}
      }
      .sendbird-reaction-badge--selected {
        ${tw`border-my-blue-80 bg-my-blue-98`}
      }

      > .sendbird-tooltip-wrapper__hover-tooltip {
        ${tw`hidden bg-pink-500`}
      }
    }

    > .sendbird-context-menu {
      ${tw`hidden`}
    }
  }

  .sendbird-emoji-reactions.outgoing {
    ${tw`justify-end`}
  }
`;

export const ChatGoogleMeetMessageBody = styled.div`
  & {
    ${tw`overflow-hidden border border-solid bg-pink-50 border-my-gray-70 rounded-xl w-[15.375rem] cursor-pointer`}
    > div:first-child {
      ${tw`flex flex-col px-4 py-3 bg-my-blue-60`}
      > div:first-child {
        ${tw`text-title-lg-700 text-my-gray-100`}
      }
      > div:last-child {
        ${tw`text-my-gray-100 text-body-md-500 opacity-[0.6]`}
      }
    }
    > div:last-child {
      ${tw`flex items-center gap-2 px-4 py-3 text-body-lg-500 text-my-gray-10 bg-my-gray-100`}
    }
  }
`;

export const ChatMessageProfile = styled.div`
  & {
    /* 유저 프로필 숨기기 */
    .sendbird-message-content__left__avatar {
      ${tw`hidden`}
    }
  }
`;

export const ChatPhotoMessage = styled.div`
  & {
    ${tw`relative max-w-[100%] w-[14rem] rounded-lg overflow-hidden cursor-pointer`}
  }
`;

export const ChatMultiplePhotoMessage = styled.div`
  & {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    padding: 0.25rem;
    border-radius: 0.75rem !important;
    cursor: pointer;

    .image-wrapper {
      ${tw`relative overflow-hidden rounded-lg cursor-pointer`}
      min-width: ${(props) =>
        `calc((100% - 1rem - ${0.5 * props.multipleFilesGridCount}rem) / ${
          props.multipleFilesGridCount
        })`};
      width: ${(props) =>
        `calc((100% - 1rem - ${0.5 * props.multipleFilesGridCount}rem) / ${
          props.multipleFilesGridCount
        })`};
      flex-grow: 1;
      height: 7rem;
      overflow: hidden;
    }
  }
`;

export const ChatSender = styled.div`
  /* 보낸 사람 이름 */
  ${tw`mb-1 text-label-sm-500 text-my-gray-10`}
`;

export const ChatMessage = styled.div`
  .sendbird-msg-hoc {
    ${tw`!m-0`}

    /* 유저 프로필 숨기기 */
    .sendbird-message-hoc__message-content.sendbird-message-content.incoming {
      ${tw`mb-1`}
      .sendbird-message-content__left {
        ${tw`hidden`}
      }
      .sendbird-message-content__middle__body-container {
        align-items: flex-start;
      }
    }

    /* 보낸 메시지의 이모지 리액션 위치 맞추기 */
    .sendbird-message-hoc__message-content.sendbird-message-content.outgoing
      .sendbird-message-content-reactions {
      ${tw`ml-auto`}
    }

    /* 이모지 리액션, 메시지 메뉴 */
    /* 위치 맞추기 */
    .sendbird-message-content__right {
      .sendbird-message-content-menu {
        transform: translateY(-100%);
        align-items: flex-start;
      }
    }
    .sendbird-message-content__left {
      .sendbird-message-content-menu {
        transform: ${(props) =>
          props.hasReactions ? 'translateY(-200%)' : ' translateY(-100%)'};
        padding-right: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
    .sendbird-message-content__right,
    .sendbird-message-content__left {
      padding-bottom: ${(props) => (props.hasReactions ? '2rem' : '')};
      .sendbird-message-content-menu {
        top: 100% !important;
      }
    }

    .sendbird-message-content__middle {
      width: ${(props) =>
        props.messageType === 'multiple-photo' ? '80%' : 'fit-content'};
      max-width: ${(props) =>
        props.messageType === 'multiple-photo' ? '80%' : '75%'};

      /* 특정 메시지에 답장 */
      .sendbird-message-content__middle__quote-message.outgoing {
        background-color: ${messageBlueColor};
      }
      .sendbird-message-content__middle__quote-message.incoming {
        background-color: ${(props) =>
          props.myUserType === UserType.TEACHER &&
          props.senderUserType === UserType.TEACHER
            ? messageBlueColor
            : messageGrayColor};
      }
      .sendbird-message-content__middle__quote-message {
        ${tw`rounded-t-xl`}
        width: 100%;
        bottom: 0 !important;
        margin-top: 0 !important;

        .sendbird-message-content__middle__quote-message__quote {
          ${tw`pt-3`}

          /*특정 메시지에 답장 - 인디케이터 */
          .sendbird-quote-message__replied-to {
            ${tw`p-0`}
            .sendbird-quote-message__replied-to__icon {
              ${tw`hidden`}
            }
            .sendbird-quote-message__replied-to__text {
              ${tw`flex flex-row-reverse px-4`}
              > span {
                ${tw`m-0 text-label-sm-500 text-my-gray-10`}
              }
              > span:first-child {
                ${tw`hidden`}
              }
              > span.sendbird-quote-message__replied-to__text__nickname {
                ${tw`hidden`}
              }
              > span.sendbird-quote-message__replied-to__text__text::before {
                content: '${(props) => props.counterpartToReply}';
              }
            }
          }

          /* 특정 메시지에 답장 - 메시지 */
          .sendbird-quote-message__replied-message {
            ${tw`w-full`}

            .sendbird-quote-message__replied-message__file-message {
              ${tw`w-full h-20 p-0 rounded-none bg-my-gray-10`}
              background-image: 
                url(${(props) => props.parentMessagePhotoThumbnail});
              background-repeat: no-repeat;
              background-size: cover;
              .sendbird-quote-message__replied-message__file-message__type-icon {
                ${tw`hidden`}
              }
            }

            .sendbird-quote-message__replied-message__thumbnail-message {
              ${tw`w-full`}
            }
            .sendbird-quote-message__replied-message__text-message {
              ${tw`w-full pt-1 pb-0 bg-transparent`}
              span {
                ${tw`w-full flex justify-end text-body-md-500 text-my-gray-50 max-h-[2.7rem] pb-1 border-b border-solid border-my-gray-80`}
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }
          }
        }
      }

      /* 메시지 */
      .sendbird-message-content__middle__body-container {
        ${tw`w-full`}

        /* 메시지 - 보낸 날짜 */
        .sendbird-message-content__middle__body-container__created-at {
          ${tw`text-my-gray-40 text-label-sm-500 !w-fit`}
          padding-bottom: ${(props) => (props.hasReactions ? '2rem' : '')};
          .sendbird-message-status__icon {
            ${(props) => props.myUserType !== UserType.TEACHER && tw`hidden`}
          }
          .sendbird-message-status__text {
            ${tw`text-my-gray-40 text-label-sm-500`}
          }
        }

        /* 메시지 - 내용 */
        .sendbird-label {
          ${tw`w-full`}
          .sendbird-message-content__middle__message-item-body {
            ${tw`w-fit px-3 py-2 cursor-pointer rounded-xl text-my-gray-10 text-body-md-500 max-w-[100%]`}

            > span.sendbird-text-message-item-body__message.edited {
              ${tw`my-auto ml-1 text-center w-fit text-my-gray-50 text-label-sm-500`}
            }
          }
          .outgoing {
            background-color: ${messageBlueColor};
            ${tw`flex justify-end`}
          }
          .incoming {
            background-color: ${(props) =>
              props.myUserType === UserType.TEACHER &&
              props.senderUserType === UserType.TEACHER
                ? messageBlueColor
                : messageGrayColor};
          }
        }

        /* 이모지 리액션 container */
        .sendbird-message-content-reactions {
          ${tw`w-1 pb-0 bg-transparent`}
        }
      }

      /* 특정 메시지에 답장한 메시지 */
      .sendbird-message-content__middle__quote-message
        ~ .sendbird-message-content__middle__body-container {
        > .sendbird-label
          > .sendbird-message-content__middle__message-item-body {
          ${tw`w-full`}
        }
        .sendbird-message-content__middle__message-item-body {
          ${tw`rounded-t-[0] pt-1`}
        }
      }
    }
  }

  /* 메시지 수정하기 */
  .sendbird-message-input__edit {
    .sendbird-message-input--textarea {
      ${tw`px-4 py-2 border border-solid rounded-lg border-my-gray-10 text-body-md-500`}
    }
    .sendbird-message-input--edit-action {
      button {
        ${tw`px-4 py-2 border border-solid rounded-lg border-my-blue-50 w-fit`}
        > span {
          ${tw`m-0 text-label-sm-700`}
        }
      }

      button.sendbird-message-input--edit-action__cancel {
        ${tw`bg-my-gray-100`}
        > span {
          ${tw`text-my-blue-50`}
        }
      }
      button.sendbird-message-input--edit-action__save {
        > span {
          ${tw`text-my-gray-100`}
        }
      }
      button.sendbird-message-input--edit-action__save.sendbird-button__disabled {
        ${tw`bg-my-blue-90 border-my-blue-90`}
      }
    }
  }
`;

export const ChannelFooter = styled.section`
  ${tw`px-4 pt-2 border-t border-solid border-my-gray-80`}
`;

export const TypingIndicator = styled.div`
  ${tw`flex w-full px-4 pb-1 bg-my-gray-100 h-fit`}
  ${tw`text-label-sm-700 text-my-gray-10`}
`;

export const ReplyingIndicator = styled.div`
  ${tw`pb-2 h-fit`}
  align-items: flex-start;
  justify-content: space-between;

  .header {
    ${tw`flex items-center justify-between w-full`}
    .counterpart {
      ${tw`text-label-sm-700 text-my-gray-10 w-fit`}
    }
  }

  .reply-message-content {
    ${tw`text-body-md-500 text-my-gray-50`}
  }
`;

export const MessageInputContainer = styled.section`
  & {
    ${tw`relative flex items-center justify-end w-full gap-2`}
  }
`;

export const MessageInput = styled.div`
  & {
    ${tw`relative flex items-center justify-end w-full gap-2 px-3 py-1 h-fit`}
    ${tw`border border-solid rounded-lg`}
    border-color: ${(props) =>
      props.isFocused ? 'var(--gray-10)' : 'var(--gray-60)'};

    > div:not(.send-button) {
      ${tw`flex flex-col flex-grow w-full h-full`}
      textarea, textarea:focus, textarea:active {
        ${tw`resize-none text-body-md-500 text-my-gray-10`}
        outline: 0;
        min-height: ${(props) => props.minHeight};
        height: ${(props) => props.minHeight};
        max-height: ${(props) => props.maxHeight};
      }
    }
    .send-button {
      ${tw`mt-auto`}
    }
  }
`;

export const ScheduledMessageListItem = styled.div`
  & {
    ${tw`flex flex-col w-full gap-2 p-4 border-b border-solid cursor-pointer bg-my-gray-100 border-my-gray-80`}
    &:hover {
      background: ${getHoveredElementBackground('bg-my-gray-100')};
    }

    > div.time {
      ${tw`flex justify-between text-label-md-500 text-my-gray-30`}
    }
    > div.content {
      ${tw`w-full text-body-md-500 text-my-gray-10`}
      overflow: 'hidden';
      text-overflow: 'ellipsis';
      display: '-webkit-box';
      -webkit-line-clamp: 2;
      -webkit-box-orient: 'vertical';
    }
  }
`;
