import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CustomGroupChannelListSkeleton = () => {
  return (
    <section>
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <div className="flex w-full gap-2 p-4 cursor-pointer" key={index}>
            <div>
              <Skeleton
                circle
                baseColor="var(--gray-80)"
                width={40}
                height={40}
              />
            </div>
            <div className="flex-grow min-w-[1px] flex flex-col justify-center">
              <Skeleton
                baseColor="var(--gray-80)"
                width={40}
                height={16}
                className="!rounded-lg"
              />
              <Skeleton
                baseColor="var(--gray-80)"
                width={232}
                height={20}
                className="!rounded-lg"
              />
            </div>
          </div>
        ))}
    </section>
  );
};

export default CustomGroupChannelListSkeleton;
