import { getSendbirdUserInfo } from '@apis/api/chat';
import {
  registerPushToken,
  TokenType,
  unregisterPushToken,
} from '@apis/api/user';
import { useUserInfoQuery } from '@apis/query/user';
import { registerFCMToSendbird, withSendbird } from '@asset/function/chat';
import { getDeviceToken } from '@asset/function/notification';
import ChatIcon, { ChatNotificationIcon } from '@asset/svg/ChatIcon';
import { useChatStore } from '@store/chat';
import { IconContainer } from '@styles/common/IconContainer.style';
import { getHoveredElementBackground } from '@styles/globalsStyles';
import { FCM_SERVICE_WORKER_SCOPE_SCRIPT_URL } from 'constants/chat';
import _ from 'lodash';
import { useQuery } from 'react-query';

const GnbChatButton = ({
  iconColor = 'var(--gray-100)',
  backgroundColor = 'transparent',
  darkMode = false,
}: {
  iconColor?: string;
  backgroundColor?: string;
  darkMode?: boolean;
}) => {
  const { data: user } = useUserInfoQuery();
  const { isOpen, setIsOpen, unreadCount } = useChatStore();
  const { data: sendbirdUserInfo } = useQuery(
    ['GET_SENDBIRD_USER_INFO', user?.userId],
    () => getSendbirdUserInfo(user?.userId),
    {
      enabled: !_.isNil(user?.userId),
    },
  );
  const sendbirdUserId = sendbirdUserInfo?.userId;
  const sendbirdAccessToken = sendbirdUserInfo?.accessToken;

  return (
    <IconContainer
      size="M"
      onClick={async () => {
        setIsOpen(!isOpen);
        if (!('Notification' in window)) {
          return;
        }
        if (Notification.permission === 'granted') {
          return;
        }
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          console.warn('Notification not permitted.');
          return;
        }
        const currentToken = await getDeviceToken();
        if (currentToken) {
          await unregisterPushToken(user.userId);
          registerPushToken(user.userId, TokenType.FCM, currentToken);
          withSendbird(sendbirdUserId, sendbirdAccessToken, (sb) =>
            registerFCMToSendbird(sb, currentToken),
          );
        }
        navigator.serviceWorker
          .getRegistration(FCM_SERVICE_WORKER_SCOPE_SCRIPT_URL)
          .then((registration) => {
            registration.showNotification('알림', {
              body: '알림이 허용되었습니다.',
              icon: '/common/logo/amc.png',
            });
          });
      }}
      className={`flex items-center justify-center`}
      bgColor={backgroundColor}
      darkMode={darkMode}
      style={{
        background: isOpen
          ? darkMode
            ? 'var(--gray-30)'
            : getHoveredElementBackground(backgroundColor)
          : '',
      }}
    >
      {unreadCount > 0 ? (
        <ChatNotificationIcon
          width="1.5rem"
          height="1.5rem"
          color={iconColor}
        />
      ) : (
        <ChatIcon width="1.5rem" height="1.5rem" color={iconColor} />
      )}
    </IconContainer>
  );
};

export default GnbChatButton;
