import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

interface VjsProps {
  // TODO: video js v8로 업데이트하면서 type없어짐
  options;
  onReady?: (player) => void;
  onPlay?: (player) => void;
}

export const VideoJS = (props: VjsProps) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady } = props;

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
      player.on('play', () => {
        props.onPlay && props.onPlay(videoElement);
      });
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef, onReady]);

  return (
    <>
      <div data-vjs-player className="video-wrapper">
        <video ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    </>
  );
};

export default VideoJS;
