import { useEffect } from 'react';

export const useKeyboardVisible = (
  isKeyboardFeatureEnabled: boolean,
  onOpenKeyboardHandler: (initialInnerHeight: number) => void = null,
  onCloseKeyboardHandler: () => void = null,
) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !window.visualViewport) {
      return;
    }

    const initialInnerHeight = window.innerHeight;

    const resizeHandler = () => {
      if (window.visualViewport!.height < initialInnerHeight) {
        window.scrollTo(0, 0);
        onOpenKeyboardHandler && onOpenKeyboardHandler(initialInnerHeight);
      } else {
        onCloseKeyboardHandler && onCloseKeyboardHandler();
      }
    };

    if (isKeyboardFeatureEnabled) {
      window.onscroll = resizeHandler;
      window.visualViewport.onresize = resizeHandler;
    }

    return () => {
      window.onscroll = null;
      window.visualViewport.onresize = null;
    };
  }, [isKeyboardFeatureEnabled]);
};
