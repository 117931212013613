import {
  GoalData,
  ReportData,
  StudyReportScheduleGroup,
} from '@asset/type/studyReport';
import { authAPI, HandleError } from '../core/instance';
import { DateTime } from 'luxon';
import { convertTimezone } from '@asset/function/i18n';
import dayjs from 'dayjs';
import { AssignmentProgressInfo } from '@asset/type/problemSolvingType';
import { RecipientType, StudyReportType } from '@asset/enum/enum';
import { StudyContentClassification } from '@asset/type/studyContent';
import { RankSummary, StudentRankInfo } from './ranking';
import qs from 'qs';
import { Classification } from '@asset/type/common/type';
import { StudyItemCountByMastery } from '@asset/type/course';
import { AssignmentType, MyContentType } from '@asset/enum/studyContentEnum';

const getReportTimeStampByTimeZone = (
  responseData,
): {
  startDateTimeStamp: number;
  endDateTimeStamp: number;
  timezoneOffset: number;
} => {
  const startDateTimeStamp: number = DateTime.fromISO(
    responseData.reportRange.startDateTime,
    {
      zone: responseData.timezone,
    },
  ).toSeconds();

  const endDateTimeStamp: number = DateTime.fromISO(
    responseData.reportRange.endDateTime,
    {
      zone: responseData.timezone,
    },
  )
    .minus({ days: 1 })
    .toSeconds();

  const timezoneOffset: number =
    convertTimezone(dayjs(), responseData.timezone, false).utcOffset() * -1;

  return { startDateTimeStamp, endDateTimeStamp, timezoneOffset };
};

export const getStudyReportData = async (
  studyReportId,
  recipientId = null,
): Promise<ReportData> => {
  let apiUrl = `/api/students/study-reports/${studyReportId}/`;
  if (recipientId) apiUrl += `?recipientId=${recipientId}`;
  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      const { startDateTimeStamp, endDateTimeStamp, timezoneOffset } =
        getReportTimeStampByTimeZone(response.data);
      return {
        ...response.data,
        startDateTimeStamp,
        endDateTimeStamp,
        timezoneOffset,
      };
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface StudyReportGoalResponse {
  success: boolean;
  data: GoalData[];
}

export const getStudyReportGoal = async (
  studyReportId: string,
): Promise<StudyReportGoalResponse> => {
  const data = await authAPI
    .get(`/api/students/study-reports/${studyReportId}/goals/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportSchedules = async (
  studyReportId: string,
  rangeType: 'NEXT' | 'CURRENT',
): Promise<{
  success: boolean;
  scheduleGroups: StudyReportScheduleGroup[];
}> => {
  const data = await authAPI
    .get(
      `/api/students/study-reports/${studyReportId}/study-schedule-groups/?rangeType=${rangeType}`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportMoteMote = async (
  studyReportId: string,
): Promise<{
  success: boolean;
  calendar: Record<string, number[]>;
}> => {
  const data = await authAPI
    .get(`/api/students/study-reports/${studyReportId}/motemote/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyContentsGroupClassification = async (
  studyReportId: string,
  myStudyContentId: number,
): Promise<{
  success: boolean;
  classification: StudyContentClassification;
}> => {
  const data = await authAPI
    .get(
      `/api/students/study-reports/${studyReportId}/my-study-contents/${myStudyContentId}/classification/`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getAssignmentProgress = async (
  studyReportId: string,
): Promise<{
  success: boolean;
  responseData: AssignmentProgressInfo;
}> => {
  const data = await authAPI
    .get(`/api/students/study-reports/${studyReportId}/assignment-progress/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportRank = async (
  studyReportId: string,
): Promise<{
  success: boolean;
  rankInfo: StudentRankInfo;
}> => {
  const data = await authAPI
    .get(`/api/students/study-reports/${studyReportId}/study-rank/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportRankSummary = async (
  studyReportId: string,
): Promise<{
  success: boolean;
  summary: RankSummary;
}> => {
  const data = await authAPI
    .get(`/api/students/study-reports/${studyReportId}/study-rank-summary/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportTestResults = async (studyReportId: string) => {
  const data = await authAPI
    .get(`/api/students/study-reports/${studyReportId}/test-reports/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportIdList = async (
  reportType: StudyReportType,
  studentId: number,
  timeRangeKey: string,
  config: {
    handleError: HandleError;
  } = null,
): Promise<{
  success: boolean;
  data: { id: number; feedbacks: { id: number; message: string }[] }[];
}> => {
  const data = await authAPI
    .get(
      `/api/students/v2/study-reports/?reportType=${reportType}&studentId=${studentId}&timeRangeKey=${timeRangeKey}`,
      config,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const postStudyReport = async (reqData: {
  reportType: StudyReportType;
  studentId: number;
  timeRangeKey: string;
}): Promise<{
  success: boolean;
}> => {
  const data = await authAPI
    .post(`/api/students/v2/study-reports/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportById = async (
  studyReportId: number,
): Promise<ReportData> => {
  const data = await authAPI
    .get(`/api/students/v2/study-reports/${studyReportId}/`)
    .then((response) => {
      const { startDateTimeStamp, endDateTimeStamp, timezoneOffset } =
        getReportTimeStampByTimeZone(response.data.data);
      return {
        ...response.data.data,
        startDateTimeStamp,
        endDateTimeStamp,
        timezoneOffset,
      };
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const patchStudyReportById = async (
  studyReportId: number,
  reqData: FormData,
): Promise<{
  success: boolean;
}> => {
  const data = await authAPI
    .patch(`/api/students/v2/study-reports/${studyReportId}/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const postStudyReportNotifications = async (
  studyReportId: number,
): Promise<{
  success: boolean;
  sendResults: {
    success: boolean;
    recipientType: RecipientType;
    resultCode: number;
    resultMessage: string;
  }[];
}> => {
  const data = await authAPI
    .post(`/api/students/v2/study-reports/${studyReportId}/notifications/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyReportFeedbacks = async (
  studyReportId: number,
): Promise<{
  success: boolean;
  data: {
    id: number;
    message: string;
  }[];
}> => {
  const data = await authAPI
    .get(`/api/students/v2/study-reports/${studyReportId}/feedbacks/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const postStudyReportFeedbacks = async (
  studyReportId: number,
  reqData: {
    message: string;
  },
): Promise<{
  success: boolean;
  data: {
    id: number;
    message: string;
  }[];
}> => {
  const data = await authAPI
    .post(`/api/students/v2/study-reports/${studyReportId}/feedbacks/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const patchStudyReportFeedbacks = async (
  studyReportFeedbackId: number,
  reqData: {
    message: string;
  },
): Promise<{
  success: boolean;
}> => {
  const data = await authAPI
    .patch(
      `/api/students/v2/study-reports/feedbacks/${studyReportFeedbackId}/`,
      reqData,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface AssignmentClassificationInfo {
  materialCourse: string;
  masteryInfo: {
    studyItemCounts: StudyItemCountByMastery[];
  };
  classification: Classification;
  relatedAssignments: {
    id: number;
    assignmentType: AssignmentType;
    myContentId: number;
    myContentType: MyContentType;
  }[];
}

export const getAssignmentsByDateRange = async (
  studentId: number,
  dueDateTimeRangeStart: string,
  dueDateTimeRangeEnd: string,
  snapshotDateTime: string = null,
): Promise<{
  success: boolean;
  data: AssignmentClassificationInfo[];
}> => {
  const apiUrl =
    `/api/study-data/assignments/classifications/` +
    `?${qs.stringify({
      studentId,
      dueDateTimeRangeStart,
      dueDateTimeRangeEnd,
      snapshotDateTime,
    })}`;
  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getWeeklyWrongTestResult = async (
  studentId: number,
  dueDateTimeRangeStart: string,
  dueDateTimeRangeEnd: string,
): Promise<{
  success: boolean;
  data: {
    id: number;
    title: string;
    isSubmitted: boolean;
    cutOffScore: number;
    countInfo: {
      gradedProblemCount: number;
      correctProblemCount: number;
    };
  };
}> => {
  const apiUrl =
    `/api/study-data/assignments/weekly-wrong-test/` +
    `?${qs.stringify({
      studentId,
      dueDateTimeRangeStart,
      dueDateTimeRangeEnd,
    })}`;
  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
