import { UserInfo } from '@asset/type/userType';
import { create } from 'zustand';

export const LOCAL_STORAGE_KEY_LOGGED_IN = 'loggedIn';

interface UserState {
  user: UserInfo;
  logIn: (userInfo: UserInfo) => void;
  logOut: () => void;
  isLoggedIn: () => boolean;
}

export const useUserStore = create<UserState>((set) => ({
  user: null,
  logIn: (userInfo) => {
    set({
      user: userInfo,
    });
    localStorage.setItem(LOCAL_STORAGE_KEY_LOGGED_IN, 'true');
  },
  logOut: () => {
    set({
      user: null,
    });
    localStorage.removeItem(LOCAL_STORAGE_KEY_LOGGED_IN);
  },
  isLoggedIn: () => {
    return localStorage.getItem(LOCAL_STORAGE_KEY_LOGGED_IN) === 'true';
  },
}));
