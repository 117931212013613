import { getUrlByQueryParameterObj } from '@asset/function/queryStringFunctions';
import { authAPI } from '../../core/instance';
import { Grade } from '@asset/enum/enum';
import {
  MaterialType,
  StudyContentType,
  TotalCourse,
} from '@asset/enum/materialEnum';
import { StudyItemType, StudyStep } from '@asset/enum/problemSolvingEnum';

interface MyClassData {
  success: boolean;
  pagination: {
    totalCount: number;
    nextPage: boolean;
    previousPage: boolean;
  };
  classroomTableData: {
    studentInfo: {
      studentId: number;
      studentName: string;
      studentSchool: string;
      studentGrade: Grade;
      studentTimezone: string;
      teacherName: string;
      studentProfileImageSrc: null;
    };
    recentStudyStatusInfo: {
      course: TotalCourse;
      materialType: MaterialType;
      materialName: string;
      myStudyContentId: number;
      myStudyContentType: StudyContentType;
      assignmentId: number;
      indexNameType: string;
      indexNumber: number;
      division: string;
      subIndexNumber: number;
      pageId: number;
      problemStatusId: number;
      problemStatusDetailId: number;
      studyStatusType: StudyStep;
      studyItemType: StudyItemType;
      elapsedTime: number;
      studentCompletedAt: string;
      updatedAt: string;
    };
    attendanceInfo: null;
    lastAttendTime: string;
    assignmentInfo: null;
    goalInfo: {
      elapsedTime: null;
      goalTime: number;
    };
    waitProblemCount: number;
    helpProblemCount: number;
    weeklyStudyReportInfo: {
      reportId: string;
      parentRecipientId: string;
    };
  }[];
}

export const getMyClassApi = async (queryStringObj): Promise<MyClassData> => {
  const queryString = getUrlByQueryParameterObj('', queryStringObj);
  const api = `/api/teachers/classroom/${queryString}`;

  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMyClassWeeklyGoalApi = async (
  queryStringObj,
): Promise<{
  success: true;
  pagination: {
    totalCount: number;
    nextPage: boolean;
    previousPage: boolean;
  };
  tableData: {
    studentInfo: {
      studentId: number;
      studentName: string;
      studentSchool: string;
      studentGrade: Grade;
      studentTimezone: string;
      teacherName: string;
      studentProfileImageSrc: string;
    };
    assignmentInfo: {
      id: number;
      subTitle: string;
      totalStudyItemCount: number;
      completedStudyItemCount: number;
      waitProblemCount: number;
    }[];
    goalInfo: {
      elapsedTime: number;
      goalTime: number;
    };
  }[];
}> => {
  const queryString = getUrlByQueryParameterObj('', queryStringObj);
  const api = `/api/teachers/goal-management/${queryString}`;

  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
