import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface EventProperties {
  properties: any;
  setProperties: (value) => void;
}
export const useEventPropertiesStore = create<EventProperties>((set) => ({
  properties: { url: '', path: '' },
  setProperties: (value) => set({ properties: value }),
}));

interface WindowPosition {
  xPercent: number;
  yPercent: number;
}

interface WindowSize {
  width: number;
  height: number;
}

interface VideoWindowStore {
  url?: string;
  position: WindowPosition;
  size: { width: number; height: number };
  setUrl?: (value: string) => void;
  setPosition?: (value: WindowPosition) => void;
  setSize?: (value: WindowSize) => void;
}

export const useVideoWindowStore = create(
  persist<VideoWindowStore>(
    (set) => ({
      url: null,
      position: null,
      size: {
        width: 640,
        height: 560,
      },
      setUrl: (value: string) =>
        set(() => ({
          url: value,
        })),
      setSize: (value: WindowSize) =>
        set(() => ({
          size: value,
        })),
      setPosition: (value: WindowPosition) =>
        set(() => ({
          position: value,
        })),
      reset: () =>
        set({
          position: null,
        }),
    }),
    {
      name: 'video-window-storage',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({ size: state.size, position: state.position }),
    },
  ),
);
