import { StudentsExtras } from '@asset/type/userType';
import { SendableMessageType } from '@sendbird/uikit-react/types/utils';
import { create } from 'zustand';

export const useChatStore = create<{
  chatRoomRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  selectedChannelUrl: string;
  unreadCount: number;
  questionDetailUrl: string;
  clickedChatNotification: { channelUrl: string };
  clickedMessageToReply: SendableMessageType;
  setChatRoomRef: (ref: React.RefObject<HTMLDivElement>) => void;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedChannelUrl: (channelUrl: string) => void;
  setUnreadCount: (count: number) => void;
  setQuestionDetailUrl: (url: string) => void;
  setClickedChatNotification: (notification: { channelUrl: string }) => void;
  setClickedMessageToReply: (message: SendableMessageType) => void;
}>((set) => ({
  chatRoomRef: null,
  isOpen: false,
  selectedChannelUrl: null,
  unreadCount: 0,
  questionDetailUrl: null,
  clickedChatNotification: null,
  clickedMessageToReply: null,
  setChatRoomRef: (ref: React.RefObject<HTMLDivElement>) =>
    set({ chatRoomRef: ref }),
  setIsOpen: (isOpen: boolean) => set({ isOpen: isOpen }),
  setSelectedChannelUrl: (channelUrl: string) =>
    set({ selectedChannelUrl: channelUrl }),
  setUnreadCount: (count: number) => set({ unreadCount: count }),
  setQuestionDetailUrl: (url: string) => set({ questionDetailUrl: url }),
  setClickedChatNotification: (notification: { channelUrl: string }) =>
    set({ clickedChatNotification: notification }),
  setClickedMessageToReply: (message: SendableMessageType) =>
    set({ clickedMessageToReply: message }),
}));

export const useGroupChannelListStore = create<{
  channelStudentExtraInfoMap: { [channelUrl: string]: StudentsExtras };
  setChannelStudentExtraInfoMap: (map: {
    [channelUrl: string]: StudentsExtras;
  }) => void;
}>((set) => {
  const resetData = () => set({ channelStudentExtraInfoMap: {} });
  setInterval(resetData, 60000);

  return {
    channelStudentExtraInfoMap: {},
    setChannelStudentExtraInfoMap: (map: {
      [channelUrl: string]: StudentsExtras;
    }) => set({ channelStudentExtraInfoMap: map }),
  };
});
