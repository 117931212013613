import ExpiryMap from 'expiry-map';
import { getMessaging, getToken } from 'firebase/messaging';
import { firebaseApp } from 'firebaseApp';
import pMemoize from 'p-memoize';

const deviceTokenCache = new ExpiryMap(10000);

async function retryGetDeviceToken(retries: number): Promise<string> {
  try {
    const messaging = getMessaging(firebaseApp);
    return await getToken(messaging);
  } catch (error) {
    if (retries === 0) {
      console.error('getToken 최대 재시도 횟수 초과:', error);
      throw error;
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return retryGetDeviceToken(retries - 1);
    }
  }
}

export const getDeviceToken = async () => {
  if (!('Notification' in window) || Notification.permission !== 'granted') {
    return null;
  }
  return await pMemoize(
    async () => {
      return await retryGetDeviceToken(5);
    },
    { cache: deviceTokenCache },
  )();
};
