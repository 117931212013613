import { getMyInformation } from '@apis/api/auth';
import { getStudentInfo } from '@apis/api/dashboard';
import { UserType } from '@asset/enum/UserEnum';
import { UserInfo } from '@asset/type/userType';
import { loginUserInfoState, selectedStudentIdAtom } from '@atoms/states';
import { useUserStore } from '@store/user';
import _ from 'lodash';
import { Settings } from 'luxon';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';

export interface StudentInfo {
  id: number;
  type: UserType;
  name: string;
  timezone: string;
  language: string;
  grade: string;
}
const getStudentData = async (studentId): Promise<StudentInfo> => {
  const studentInfoData = await getStudentInfo(studentId);
  if (!_.isNil(studentInfoData)) {
    return {
      id: studentId,
      type: UserType.STUDENT,
      name: studentInfoData.name,
      timezone: studentInfoData.timezone,
      language: studentInfoData.language,
      grade: studentInfoData.grade,
    };
  } else {
    return null;
  }
};

export function useSelectedStudentInfoQuery() {
  const [selectedStudentId] = useRecoilState(selectedStudentIdAtom);

  return useQuery(
    ['GET_STUDENT_INFO_QUERY', selectedStudentId],
    async () => {
      const studentData = await getStudentData(selectedStudentId);
      Settings.defaultZone = studentData.timezone;
      return studentData;
    },
    {
      enabled: Boolean(selectedStudentId),
      cacheTime: 0,
      staleTime: 1 * 60 * 1000,
    },
  );
}

export function useUserInfoQuery() {
  // TODO: 하위 호환을 위해 당분간 남겨두지만 추후 삭제
  const [userLegacy, setUserLegacy] =
    useRecoilState<UserInfo>(loginUserInfoState);
  const { user, logIn, isLoggedIn } = useUserStore();
  return useQuery(
    ['GET_USER_INFO_QUERY', user?.userId],
    async () => {
      // TODO: zustand의 store로 통합하기 위해서 recoil 쪽은 null 세팅해 준다.
      setUserLegacy(null);
      if (!userLegacy?.id && !isLoggedIn()) {
        return null;
      }
      let userResult = user;
      if (!userResult?.id) {
        const userReponse = await getMyInformation();
        if (userReponse.success) {
          userResult = userReponse.data;
        } else {
          return null;
        }
      }
      if (userResult?.type === UserType.STUDENT) {
        const studentData = await getStudentData(userResult.id);
        userResult = { userId: userResult.userId, ...studentData };
      }
      logIn(userResult);
      return userResult;
    },
    {
      staleTime: user?.userId ? 5 * 60 * 1000 : 0,
    },
  );
}
