import React, { useCallback, useEffect, useState } from 'react';
import VideoJS from '@components/common/video/VideoJS';

const BasicVideo = ({
  lectureUrl,
  playPIP = false,
  fluid = false,
  noVideoView = null,
}) => {
  const [canPlayVideo, setCanPlayVideo] = useState(false);
  const playerRef = React.useRef(null);
  const [videoSrc, setVideoSrc] = useState(lectureUrl);

  useEffect(() => {
    setVideoSrc(lectureUrl);
  }, [lectureUrl]);

  const convertVideoURL = useCallback(
    (url) => {
      const videoExtension = url.slice(-4);
      if (videoExtension.includes('m3u8')) {
        url = url.slice(0, -4);
        url = url + 'mp4';
        setVideoSrc(url);
      } else if (videoExtension.includes('mp4')) {
        url = url.slice(0, -3);
        url = url + 'mov';
        setVideoSrc(url);
      }
      return url;
    },
    [setVideoSrc],
  );

  const videoJsOptions = {
    fill: true,
    autoplay: false,
    controls: true,
    responsive: false,
    fluid: fluid,
    sources: [
      {
        src: videoSrc,
        type: 'video/mp4',
      },
    ],
    playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
  };

  const handlePlayerReady = useCallback(
    (player) => {
      playerRef.current = player;

      player.on('error', () => {
        const videoExtension = videoSrc.slice(-4);
        if (videoExtension.includes('m3u8') || videoExtension.includes('mp4')) {
          convertVideoURL(videoSrc);
        } else {
          setCanPlayVideo(false);
        }
      });

      player.on('mouseleave', () => {
        player.controlBar.hide();
      });

      player.on('mouseenter', () => {
        player.controlBar.show();
      });

      player.on('touchstart', () => {
        player.controlBar.show();
        setTimeout(() => player.controlBar.hide(), 5000);
      });
    },
    [videoSrc],
  );

  useEffect(() => {
    if (!lectureUrl && lectureUrl === '') {
      setCanPlayVideo(false);
    } else {
      setCanPlayVideo(true);
    }
  }, [setCanPlayVideo]);

  return (
    <>
      {canPlayVideo ? (
        <span className="w-full h-full video">
          <VideoJS
            options={videoJsOptions}
            onReady={handlePlayerReady}
            onPlay={(videoElement) => {
              if (playPIP) {
                if (
                  videoElement.webkitSupportsPresentationMode &&
                  typeof videoElement.webkitSetPresentationMode === 'function'
                ) {
                  videoElement.webkitSetPresentationMode(
                    videoElement.webkitPresentationMode === 'picture-in-picture'
                      ? 'inline'
                      : 'picture-in-picture',
                  );
                } else videoElement.requestPictureInPicture();
              }
            }}
          />
        </span>
      ) : (
        <>{noVideoView && noVideoView}</>
      )}
    </>
  );
};

export default React.memo(BasicVideo);
