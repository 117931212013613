import React, { useRef } from 'react';
import BasicVideo from './BasicVideo';
import CloseIcon from '@asset/svg/CloseIcon';
import IconContainer from '../IconContainer';
import { useVideoWindowStore } from '@store/common';
import { Rnd } from 'react-rnd';
import _ from 'lodash';

const DraggableVideoWindow = ({ lectureUrl, onClose }) => {
  const dragRef = useRef(null);
  const { size, position, setSize, setPosition } = useVideoWindowStore();

  const defaultPositionX = (window.innerWidth - size.width) / 2;
  const defaultPositionY = (window.innerHeight - size.height) / 2;

  const positionX = (position?.xPercent * window.innerWidth) / 100;
  const positionY = (position?.yPercent * window.innerHeight) / 100;

  return (
    <>
      <Rnd
        className="!fixed z-[999999] top-0 left-1/2 bg-my-gray-90 rounded-xl border-my-gray-70 border border-solid"
        style={{
          boxShadow: '0px 8px 24px 0px rgba(23, 32, 42, 0.16)',
        }}
        onDragStop={(e, data) => {
          setPosition({
            xPercent: (data.lastX * 100) / window.innerWidth,
            yPercent: (data.lastY * 100) / window.innerHeight,
          });
        }}
        default={{
          x:
            !_.isNil(positionX) && !_.isNaN(positionX)
              ? positionX
              : defaultPositionX,
          y:
            !_.isNil(positionY) && !_.isNaN(positionY)
              ? positionY
              : defaultPositionY,
          width: size.width,
          height: size.height,
        }}
        position={{
          x:
            !_.isNil(positionX) && !_.isNaN(positionX)
              ? positionX
              : defaultPositionX,
          y:
            !_.isNil(positionY) && !_.isNaN(positionY)
              ? positionY
              : defaultPositionY,
        }}
        cancel=".video-container, .close-btn"
        minWidth={'480px'}
        minHeight={'320px'}
        maxWidth={'1080px'}
        maxHeight={'810px'}
        onResizeStop={(e, direction, ref, delta, position) => {
          setSize({
            width: ref.clientWidth,
            height: ref.clientHeight,
          });
          setPosition({
            xPercent: (position.x * 100) / window.innerWidth,
            yPercent: (position.y * 100) / window.innerHeight,
          });
        }}
        bounds={'parent'}
      >
        <div ref={dragRef} className="w-full h-full">
          <div className="flex flex-col w-full h-full">
            <div className="flex justify-between px-4 py-2 text-title-sm-700 text-my-gray-10">
              해설 영상
              <IconContainer
                icon={
                  <CloseIcon width="16" height="16" color="var(--gray-10)" />
                }
                className="close-btn"
                size={'S'}
                onClick={onClose}
              />
            </div>
            <div className="flex-1 px-1 pb-1">
              <div className="w-full h-full overflow-hidden rounded-lg cursor-default video-container">
                <BasicVideo lectureUrl={lectureUrl} />
              </div>
            </div>
          </div>
        </div>
      </Rnd>
    </>
  );
};

export default DraggableVideoWindow;
