import axios from 'axios';
import { authAPI } from '../core/instance';
import { CommonResponse } from '@apis/common/response';
import { UserType } from '@asset/enum/UserEnum';
import qs from 'qs';

export const usersGoogleLoginCallback = async (code) => {
  const response = await authAPI
    .post('/api/users/google/login/callback/', {
      code,
    })
    .then((response) => {
      const { access_token } = response.data;
      authAPI.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${access_token}`;
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
};

export const usersGoogleAuthCallback = async (code, scopes) => {
  const response = await authAPI
    .post('/api/users/google/oauth/callback/', {
      code,
      scopes,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
};

export const usersLogOut = async () => {
  const response = await authAPI
    .post('/api/users/token/blacklist/')
    .then(() => {
      authAPI.defaults.headers.common['Authorization'] = '';
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
};

export const userLogin = async (requestData) => {
  const data = await authAPI
    .post(`/api/users/login/`, requestData)
    .then((response) => {
      if (response.data.success) {
        const { access_token } = response.data;
        authAPI.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`;
      }
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const userSignUp = async (requestData) => {
  const data = await authAPI
    .post(`/api/users/signup/`, requestData)
    .then((response) => {
      if (response.data.success) {
        window.location.replace(
          `/account/signup/email-authentication/?email=${encodeURIComponent(
            requestData.email,
          )}`,
        );
      }
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const userSignUpV2 = async (requestData: {
  userType: UserType;
  email: string;
  password: string;
  signUpCode: string;
}): Promise<{
  success: boolean;
  message?: string;
  code?: string;
}> => {
  const data = await authAPI
    .post(`/api/users/v2/sign-up`, requestData)
    .then((response) => {
      if (response.data.success) {
        window.location.replace(
          `/account/signup/email-authentication/invitation?${qs.stringify(
            {
              email: requestData.email,
            },
            { skipNulls: true },
          )}`,
        );
      }
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const patchUserCode = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/code/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const checkEmailAuthentication = async () => {
  const data = await authAPI
    .get(`/api/users/auth/email/check/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const sendEmailAuthentication = async () => {
  const data = await authAPI
    .get(`/api/users/auth/email/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const userEmailAuthenticationCheck = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/auth/email/complete/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const userVerifyEmailAuthenticationV2 = async (requestData: {
  verificationCode: string;
}): Promise<{
  success: boolean;
  access_token: string;
}> => {
  const data = await authAPI
    .post(`/api/users/v2/verify`, requestData)
    .then((response) => {
      if (response.data.success) {
        const { access_token } = response.data;
        authAPI.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`;
      }
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const resetPasswordEmailCheck = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/auth/reset-password/email/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const resetPassword = async (requestData) => {
  const data = await authAPI
    .patch(`/api/users/password/reset/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postPhoneNumberVerifications = async (requestData: {
  phoneNumber: string;
}) => {
  const data = await axios
    .create({
      baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
      withCredentials: true,
    })
    .post(`/api/users/phone-number-verifications/`, requestData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return data;
};

export const postPhoneNumberAuths = async (requestData: {
  phoneNumber: string;
  verificationCode: string;
}) => {
  const data = await axios
    .create({
      baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
      withCredentials: true,
    })
    .post(`/api/users/phone-number-auths/`, requestData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return data;
};

export const getMyInformation = async (): Promise<
  CommonResponse & {
    data: {
      type: UserType;
      userId: number;
      id: number;
      name: string;
      language: string;
      timezone: string;
    };
  }
> => {
  const data = await authAPI
    .get(`/api/users/my-info/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return data;
};

export const sendUserVerificationEmails = async (requestBody: {
  email: string;
}): Promise<{
  success: boolean;
}> => {
  const response = await authAPI
    .post('/api/users/v2/verification-emails', requestBody)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
};
