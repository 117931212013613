import { CommonResponse } from '@apis/common/response';
import { authAPI } from '@apis/core/instance';

export const registerPushToken = async (
  userId: number,
  tokenType: TokenType,
  token: string,
): Promise<CommonResponse> => {
  const data = authAPI
    .post<CommonResponse>(`/api/users/${userId}/push-tokens`, {
      tokenType,
      token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export enum TokenType {
  FCM = 'FCM',
}

export const unregisterPushToken = async (
  userId: number,
  tokenType?: TokenType,
  token?: string,
): Promise<CommonResponse> => {
  const query =
    tokenType && token ? `?tokenType=${tokenType}&token=${token}` : '';
  const data = authAPI
    .delete<CommonResponse>(`/api/users/${userId}/push-tokens${query}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
